import request from '@/service/request'

/* 健身房 时间表，分页
* @params
* */
export const pageGymScheduleApi = (params) => {
  return request.post('/stu/gymRoomSchedule/page', params)
}

/* 健身房 时间表，list 获取所有权限 管理员和开发人员
* 接口地址准确 因为后端颠颠的，乱来 ，导致几个cell接口命名不规范，跟羽毛球的相反
* @params
* */
export const cellGymScheduleApi = (params = {}) => {
  return request.post('/stu/gymRoomSchedule/cellManager', params)
}

/* 健身房 时间表，根据核销员权限获取
* @params
* */
export const cellGymScheduleByManagerApi = (params = {}) => {
  return request.post('/stu/gymRoomSchedule/cell', params)
}

/* 健身房 时间表，根据id获取
* @params
* */
export const getGymScheduleByIdApi = (id) => {
  return request.post(`/stu/gymRoomSchedule/get/${id}`)
}
/* 健身房 时间表，删除
* @params
* */
export const delGymScheduleByIdApi = (id) => {
  return request.post(`/stu/gymRoomSchedule/del/${id}`)
}

/* 健身房 时间表，保存
* @params
* */
export const batchBindGymScheduleByUserIdApi = (params) => {
  return request.post('/stu/gymRoomSchedule/manager/saveBatch', params)
}

/* 健身房 时间表，管理员list
* @params
* */
export const listGymAdminApi = (params = {}) => {
  return request.post('/stu/gymRoomSchedule/manager/list', params)
}

/* 球场模板 type=1 */

/* 健身房 时间表，模板记录
* @params
* */
export const listGymModelApi = (params = {}) => {
  return request.post('/stu/gymRoomSchedule/list', params)
}

/* 健身房 时间表，删除模板
* @params
* */
export const delGymModelApi = (ids) => {
  return request.post(`/stu/gymRoomSchedule/del/${ids}`)
}

/* 健身房 时间表，(多对多)的模板保存
* @params
* */
export const saveGymModelApi = (params) => {
  return request.post('/stu/gymRoomSchedule/saveBatchByRoomIdsAndWeeks', params)
}

/* 健身房 时间表，编辑当日 启用/禁用后的模板保存
* @params
* */
export const editBatchGymOffFlagApi = (params) => {
  return request.post('/stu/gymRoomSchedule/saveCustomBatchByRoomId', params)
}
