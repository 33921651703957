<!--  健身房排期  -->
<template>
  <div class="gym-schedule" v-loading="loading">
    <div class="gym-schedule__header">
      <el-form :model="queryInfo" inline>
        <el-form-item label="校区">
          <el-radio-group ref="radioRef" v-model="queryInfo.campusId" :disabled="isEditing" @change="renderTable">
            <el-radio-button v-for="{ campusName, id } in campusList" :key="id" :label="id">{{ campusName }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="queryInfo.day" value-format="yyyy-MM-dd" type="date" format="yyyy-MM-dd"
                          :disabled="isEditing" @change="renderTable" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="isEditing" class="mb-1 font-right" style="overflow: hidden">
      <el-button-group class="mr-1" v-if="isEditor">
        <el-button type="warning" plain @click="setOffFlag(1)">一键禁用
        </el-button>
        <el-button type="success" plain @click="setOffFlag(0)">一键启用
        </el-button>
      </el-button-group>
      <el-button @click="cancel">取消</el-button>
      <el-button v-if="isEditor" type="primary" @click="save">保存</el-button>
    </div>
    <div v-else class="mb-1 font-right">
      <el-button v-if="isEditor&&scheduleList.length" type="primary" @click="handleEdit">编辑
      </el-button>
    </div>
    <el-scrollbar style="height:calc(100% - 80px)">
      <div v-if="scheduleList.length" class="schedule-grid">
        <div class="schedule-grid__header flex">
          <div class="schedule-grid__label font-center" v-for="(item, index) in scheduleList" :key="index">
            <b class="room-name">{{ item.gymRoomName }}</b>
            <template v-if="item.gymScheduleVos.length && isEditing && isEditor">
              <el-button-group class="ml-1 room-btn" size="mini">
                <el-tooltip class="item" effect="dark" content="列禁用" placement="top-start">
                  <el-button size="mini" icon="el-icon-turn-off" type="warning" plain @click="switchColumn(1, item)">
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="列启用" placement="top-start">
                  <el-button size="mini" icon="el-icon-open" type="success" plain @click="switchColumn(0, item)" />
                </el-tooltip>
              </el-button-group>
            </template>
          </div>
        </div>
        <div class="schedule-grid__body flex-column">
          <div class="schedule-grid__wrap  flex schedule-grid__body-top">
            <div class="schedule-grid__column" v-for="(item, index) in scheduleList" :key="index">
              <div v-for="subItem in item.gymScheduleVos" :class="['schedule-grid__item', 'flex-column', { 'is-editing': isEditing },
              {'is-order':subItem.orderFlag},
              {'is-disabled':subItem.offFlag},
              {'is-on':subItem.offFlag===0}]"
                   :key="subItem.id">
                <template v-if="subItem.startTimeStr && subItem.endTimeStr">
                  <div class="time-mark font-center mb-1">{{ subItem.startTimeStr }}-{{ subItem.endTimeStr }}
                    <i v-if="subItem.orderFlag" class="el-icon-success fr mr-1" title="已被预约"></i>
                  </div>
                  <el-switch v-if="isEditing" v-model="subItem.offFlag" class="mb-1" :active-value="0"
                             active-text="启用"
                             active-color="var(--color-success)" :inactive-value="1" inactive-text="禁用"
                             @change="handleSwitch($event, subItem)" />
                  <template v-else>
                    <el-tag v-show="subItem.offFlag" type="info">禁用</el-tag>
                    <el-tag v-show="subItem.offFlag === 0" type="success">启用</el-tag>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {
  cellGymScheduleByManagerApi,
  cellGymScheduleApi,
  editBatchGymOffFlagApi
} from '@/api/student-manage/gym-schedule-api'
import { cloneDeep } from 'lodash'
import store from '@/store'

export default {
  name: 'GymSchedule',
  data() {
    return {
      isEditing: false,
      isAdmin: false,
      campusList: [], // 校区list
      scheduleList: [], // 球场排期表
      initDataList: [], // 存初始化数据
      queryInfo: {
        campusId: null,
        day: this.$moment().format('YYYY-MM-DD'),
        orderQueryFlag: 1 // 用于获取格式是否已被预约
      },
      isEditor: false, // 编辑状态禁止跳转切换
      loading: false, // 遮罩
      curTab: '1'
    }
  },
  async mounted() {
    const _permission = store.getters['login/getPermission']
    this.isEditor = this.$store.state.login.permissionTable.includes('gym:schedule:edit')
    this.isAdmin = !!(this.$store.state.login.isAdmin || (_permission.includes('gym:schedule:edit')))
    await this.getCampusList()
    await this.renderTable()
  },
  methods: {
    // 判断是否可编辑时间
    isBeforeNow() {
      // 选中日期为当日时
      if (this.$moment(this.queryInfo.day, 'YYYY-MM-DD', true).isSame(this.$moment().startOf('day'))) {
        return this.$moment().isBefore(this.$moment().startOf('day').add(12, 'hours').add(31, 'minutes'))
      } else {
        return this.$moment(this.queryInfo.day).isAfter(this.$moment())
      }
    },
    async renderTable() {
      try {
        if (!this.queryInfo.day) {
          this.queryInfo.day = this.$moment().format('YYYY-MM-DD')
        }
        // 除了非管理员，无法编辑外，非未来日期也不可编辑，但可以查看
        this.isEditor = this.isAdmin && this.isBeforeNow()

        if (!this.queryInfo.campusId) {
          this.queryInfo.campusId = this.campusList[0].id
        }
        let res
        if (this.isAdmin) {
          // 超级管理员
          res = await cellGymScheduleApi(this.queryInfo)
        } else {
          // 按分配的球场
          res = await cellGymScheduleByManagerApi(this.queryInfo)
        }
        this.scheduleList = res?.data?.gymRoomScheduleVos || []
        if (res.data) {
          const arr = []
          res.data.gymRoomScheduleVos.forEach(column => {
            column.gymScheduleVos.forEach(subItem => {
              arr.push(subItem)
            })
          })
          this.initDataList = cloneDeep(arr)
        }
      } catch (e) {
        console.error(e)
      }
    },
    handleEdit() {
      this.isEditor = this.isAdmin && this.isBeforeNow()
      if (this.isEditor) {
        this.isEditing = true
        this.renderTable()
      } else {
        this.isEditing = false
        this.$message.error('已超出可编辑时间！')
      }
    },
    // 获取校区
    async getCampusList() {
      try {
        const { data } = await this.$http.getCampusListQuery({})
        this.campusList = data
      } catch (e) {
        console.error(e)
      }
    },
    cancel() {
      this.isEditing = false
      this.renderTable()
    },
    handleSwitch(val, subItem) {
      if (val && subItem.orderFlag) {
        this.$confirm('该时间段已被预约，是否确认禁用?', '确认信息').then().catch(() => {
          subItem.offFlag = 0
        })
      }
    },
    // 启用/禁用列
    switchColumn(status, column) {
      let hasOrderFlag = column.gymScheduleVos.filter(item => item.orderFlag)
      hasOrderFlag = hasOrderFlag.map(item => `${item.startTimeStr}-${item.endTimeStr}`).join(',')
      if (status && hasOrderFlag) {
        this.$confirm(`${column.gymRoomName}以下时间段已被预约：${hasOrderFlag}，您确定要禁止${this.queryInfo.day}的所有预约吗？`).then(() => {
          column.gymScheduleVos.forEach(item => {
            item.offFlag = status
          })
        })
      } else {
        this.$confirm(`是否确认${status ? '禁用' : '启用'}整列？`).then(() => {
          column.gymScheduleVos.forEach(item => {
            item.offFlag = status
          })
        })
      }
    },
    // 开启/禁用当日全部
    setOffFlag(status) {
      let hasOrderFlag = []
      this.scheduleList.forEach(col => {
        hasOrderFlag = hasOrderFlag.concat(col.gymScheduleVos.filter(item => item.orderFlag))
      })
      hasOrderFlag = hasOrderFlag.map(item => `${item.gymRoomName}(${item.startTimeStr}-${item.endTimeStr})`).join(',')
      if (status && hasOrderFlag) {
        this.$confirm(`以下场地和时间段已被预约：${hasOrderFlag}，是否确认禁用当日所有球场？`).then(() => {
          this.scheduleList.forEach(({ gymScheduleVos }) => {
            gymScheduleVos.forEach(subItem => {
              subItem.offFlag = status
            })
          })
        })
      } else {
        this.$confirm(`是否确认${status ? '禁用' : '启用'}当日所有球场？`).then(() => {
          this.scheduleList.forEach(({ gymScheduleVos }) => {
            gymScheduleVos.forEach(subItem => {
              subItem.offFlag = status
            })
          })
        })
      }
    },
    save() {
      if (!this.isBeforeNow()) {
        this.handleEdit()
        return
      }
      this.$confirm('是否保存编辑？').then(async () => {
        try {
          this.loading = true
          const result = []
          // id 首次编辑 不需要传id,二次编辑时必须存在item.id
          this.scheduleList.forEach(column => {
            column.gymScheduleVos.forEach(item => {
              const isChanged = this.initDataList.find(i => i.gymRoomId === item.gymRoomId && i.startTime === item.startTime && i.endTime === item.endTime)
              if (isChanged.offFlag !== item.offFlag) {
                const target = {
                  day: item.day,
                  id: item.id,
                  gymRoomId: item.gymRoomId,
                  endTime: item.endTime,
                  startTime: item.startTime,
                  offFlag: item.offFlag
                }
                result.push(target)
              }
            })
          })
          if (!result.length) {
            this.$message.error('表格未修改！')
            return
          }
          await editBatchGymOffFlagApi({ gymRoomSchedules: result })
          this.$message.success('修改成功')
          this.isEditing = false
          await this.renderTable()
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }).catch()
    }
  }
}
</script>
<style lang="scss">
.gym-schedule {
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
  min-width: 760px;
  min-height: 400px;
  overflow: hidden;
  height: calc(100vh - var(--header-height));

  .gym-schedule__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .schedule-grid__label {
    .room-name {
      font-size: 18px;
      line-height: 60px;
    }

    .room-btn {
      font-size: 16px;
    }
  }

  .schedule-grid .schedule-grid__column {
    justify-content: flex-start;

    .schedule-grid__item {
      flex: unset;
      justify-content: flex-start;
      height: 90px;
      border-bottom: 1px solid #DCDFE6;

      &.is-on {
        background: #f0f9ec;

        .time-mark {
          background: #87cf61;
          color: #fff;
        }
      }

      &.is-disabled {
        background: #f4f4f5;

        .time-mark {
          background: #e8e8e8;
        }
      }
    }

    .time-mark {
      width: 100%;
      color: #638391;
      padding: 6px 0;
      background: #ebf4f9;

      .el-icon-success {
        color: var(--color-success);
      }
    }
  }
}
</style>
